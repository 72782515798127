<template>
  <div class="notification">
    <b-alert
      :variant="variant"
      fade
      :show="showNotification"
      @dismissed="dismissCountDown=0"
      @dismiss-count-down="countDownChanged"
    >{{ text }}</b-alert>
  </div>
</template>

<script>

import {
  BAlert,
} from 'bootstrap-vue'

export default {
  components: {
    BAlert,
  },
  props: {
    variant: {
      type: String,
      default: 'primary',
    },
    text: {
      type: String,
      default: null,
    },
    dismissSecs: {
      type: Number,
      default: 5,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dismissCountDown: 0,
    }
  },
  computed: {
    showNotification() {
      if (this.show) {
        return this.dismissSecs
      }
      return 0
    },
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },
  },
}
</script>
